import _ from 'underscore';
import ApiService from '@/util/api.service';
import moment from 'moment';

const ol = window.ol;

const OutagesStore = class {
  constructor() {
    if (OutagesStore.instance) {
      return OutagesStore.instance;
    }
    this.outagesMap = {};
    this.handlers = [];
    OutagesStore.instance = this;
  }

  subscribe(fn) {
    this.handlers.push(fn);
  }

  notify(item) {
    this.handlers.forEach((fn) => {
      fn(item);
    });
  }

  async loadData() {
    const initDate = moment().subtract(12, 'hours').toDate();
    const endDate = new Date();
    try {
      const data = await ApiService({
        url: '/reports/cameras/outages',
        method: 'get',
        params: {
          from: initDate.toISOString(),
          to: endDate.toISOString(),
        },
      });
      this.outagesMap = {};
      data.reverse().forEach((item) => {
        if (this.outagesMap[item.cameraId]) {
          this.outagesMap[item.cameraId].items.push(item);
        } else {
          this.outagesMap[item.cameraId] = {
            name: item.name,
            cameraId: item.cameraId,
            active: item.active,
            durationMs: item.active ? 0 : item.durationMs,
            createdAt: item.createdAt,
            items: [item],
          };
        }
      });
      return _.toArray(this.outagesMap);
    } catch (error) {
      console.error('Error al cargar las salidas de camaras', { error });
    }
    return []
  }

  async loadPmi(cameraId) {
    const initDate = moment().subtract(12, 'hours').toDate();
    const endDate = new Date();
    try {
      const data = await ApiService({
        url: '/reports/cameras/outages',
        method: 'get',
        params: {
          cameraId: [cameraId],
          from: initDate.toISOString(),
          to: endDate.toISOString(),
        },
      });
      data.reverse().forEach((item, index) => {
        if (index > 0) {
          this.outagesMap[item.cameraId].items.push(item);
        } else {
          this.outagesMap[item.cameraId] = {
            name: item.name,
            cameraId: item.cameraId,
            durationMs: item.active ? 0 : item.durationMs,
            createdAt: item.createdAt,
            items: [item],
          };
        }
      });
      this.notify(_.toArray(this.outagesMap));
    } catch (error) {
      console.error('Error al cargar las salidas de camaras', { error });
    }
  }
};

export default OutagesStore;
